<template>
  <div class="news-page">
    <el-card>
      <nav class="out">
        <el-button
          type="primary"
          class="btnAdd"
          size="small"
          @click="dialogVisible = true"
          v-show="$store.state.btn_if.indexOf('news_list_add') != -1"
          >新增快讯</el-button
        >
        <el-button
          type="primary"
          class="btnAdd"
          size="small"
          @click="addkuai"
          v-show="$store.state.btn_if.indexOf('news_list_temp') != -1"
          >快讯采集</el-button
        >
        <nav class="input" style="width: 150px;">
          <el-input
            v-model="S_form.authName"
            size="medium"
            placeholder="编辑人员"
          ></el-input>
        </nav>
        <nav class="input" style="width: 160px;">
          <el-button @click="S_reset" size="small">重置</el-button>
          <el-button @click="S_subimt" type="primary" size="small">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
        </nav>
      </nav>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号" sortable="custom">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="ew">
            <p>
              <a
                :href="`https:/${yu}/news/${ew.row.id}.html`"
                target="“_blank”"
                style="  text-decoration: underline;"
                >{{ ew.row.title }}</a
              >
            </p>
            <a :href="ew.row.originLink" target="“_blank”">(原文链接)</a>
            <p v-if="ew.row.preview">
              <a :href="ew.row.preview" target="“_blank”">
                (PC预览)
              </a>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          width="300"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="authName" label="编辑人员"> </el-table-column>
        <el-table-column prop="picture" label="配图">
          <template slot-scope="ew">
            <img
              v-if="ew.row.picture"
              :src="baseurl + yu + ew.row.picture"
              alt=""
              style="width: 150px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="source" label="来源"> </el-table-column>
        <!-- <el-table-column prop="originLink" label="原文链接">
          <template slot-scope="ew">
            <a :href="ew.row.originLink" target="“_blank”">{{
              ew.row.originLink
            }}</a>
          </template>
        </el-table-column> -->

        <el-table-column prop="" label="状态">
          <template slot-scope="ew">
            <el-tag type="warning" v-if="ew.row.publishStatus == 1"
              >正常</el-tag
            >
            <el-tag type="warning" v-else>隐藏</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="ew">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(ew.row.id)"
              class="skyblue"
              v-show="$store.state.btn_if.indexOf('news_list_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.id)"
              class="red"
              v-show="$store.state.btn_if.indexOf('news_list_delete') != -1"
              >删除</el-button
            >

            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              @click="show(ew.row.id, 0)"
              class="yellow"
              v-if="ew.row.publishStatus == 1"
              v-show="$store.state.btn_if.indexOf('news_list_hide') != -1"
              >隐藏</el-button
            >
            <el-button
              type="text"
              icon="el-icon-setting"
              size="mini"
              @click="show(ew.row.id, 1)"
              class="yellow"
              v-show="$store.state.btn_if.indexOf('news_list_hide') != -1"
              v-else
              >显示</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="新增快讯" :visible.sync="dialogVisible" width="50%">
      <el-form
        ref="formref"
        :model="form"
        :rules="addFormRulue"
        label-width="80px"
      >
        <el-form-item label="快讯标题" prop="title">
          <el-input
            v-model="form.title"
            :maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input
            v-model="form.source"
            :maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="form.picture"
              :src="baseurl + yu + form.picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input v-model="form.content" type="textarea" :rows="7"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false
            form = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="资讯修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form
        ref="formref_1"
        :model="form_1"
        :rules="addFormRulue"
        label-width="80px"
      >
        <el-form-item label="快讯标题" prop="title">
          <el-input
            v-model="form_1.title"
            :maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input
            v-model="form_1.source"
            :maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :headers="header"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img
              v-if="form_1.picture"
              :src="baseurl + yu + form_1.picture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="form_1.content"
            type="textarea"
            :rows="7"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblexui = false">取 消</el-button>
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="快讯列表" :visible.sync="diokuai" width="50%">
      <nav class="out">
        <nav class="input" style="width: 150px">
          <el-select placeholder="来源" v-model="source" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="36氪" value="36氪"></el-option>
            <el-option label="钛媒体" value="钛媒体"></el-option>
            <el-option label="创业邦" value="创业邦"></el-option>
          </el-select>
        </nav>

        <nav class="input">
          <!-- <el-button @click="reset" size="small">重置</el-button> -->
          <el-button @click="subimt" type="primary" size="small">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
          <el-button @click="rest" size="small">{{ '重置' }}</el-button>
        </nav>
      </nav>
      <el-table
        :data="lista"
        style="width: 100%"
        border
        stripe
        :row-key="
          row => {
            return row.id
          }
        "
        :header-cell-style="rowClass"
        @selection-change="sel"
        ref="multipleTable"
      >
        <!-- <el-table-column prop="id" width="100" label="编号"> </el-table-column> -->
        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
          <template slot-scope="{ row }">
            <p>{{ row.title }}</p>
            <a :href="row.originLink" target="“_blank”" v-if="!!row.originLink"
              >(原文链接)</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" width="380">
        </el-table-column>

        <el-table-column prop="source" label="来源"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPagea"
        :total="totala"
        :page-size="pagesizea"
        @size-change="handleSizeChangea"
        @current-change="handleCurrentChangea"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diokuai = false">取 消</el-button>
        <el-button type="primary" @click="addskuai">{{
          btn ? '添 加' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  // 111
  data() {
    return {
      action: {
        action: 'news'
      },
      S_form: {
        authName: ''
      },
      mution: [],
      source: '',
      addFormRulue: {
        title: [{ required: true, message: '请输入快讯标题', trigger: 'blur' }],
        source: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      btn: true,
      currentPage: 1,
      pagesize: 10,
      currentPagea: 1,
      pagesizea: 5,
      list: [],
      value: '',
      totala: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      order: '',
      form: {
        title: '',
        source: '',
        picture: '',
        content: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      diokuai: false,
      lista: [],
      fastid: '',
      mt: []
    }
  },
  updated() {
    //   console.log(this.form);
  },
  methods: {
    subimt() {
      this.getlista()
    },
    S_subimt() {
      this.currentPage = 1
      this.getlist()
    },
    S_reset() {
      this.S_form = {
        authName: ''
      }
      this.getlist()
    },
    rest() {
      ;(this.source = ''), this.getlista()
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else {
          s = 'news_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },

    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    handleSizeChangea(e) {
      this.pagesizea = e
      console.log(e)
    },
    handleCurrentChangea(e) {
      this.mution = [...this.mution, ...this.mt]
      // var op = this.mt
      // this.mution.forEach(item => {
      //   if (op.indexOf(item) == -1) {
      //     op.push(item)
      //   }
      // })
      // console.log(op)
      // op.forEach(item=>{})
      // var that = this
      // this.$refs.multipleTable.toggleAllSelection(this.mution)
      // console.log(this.mution)
      this.currentPagea = e
      this.getlista()
      // this.toggleSelection([...this.mution])

      // setTimeout(() => {
      //   this.mution.forEach(row => {
      //     this.$refs.multipleTable.toggleRowSelection(row)
      //   })
      // }, 500)
    },
    getlist() {
      this.$http
        .get('/admin/News/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order,
            ...this.S_form
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
          }
        })
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.picture = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      }
    },
    sel(val) {
      // console.log(val)
      // this.mution=val
      this.mt = val

      var se = this.mt.map(item => item.id)
      // console.log(this.mution)
      console.log(se)

      this.fastid = se.join(',')
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.picture = res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    add() {
      this.$refs.formref.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/News/add', this.form)
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.dialogVisible = false
                location.reload()
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
            })
        }
        this.btn = false
      })
    },
    addedit() {
      this.$refs.formref_1.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/News/edit', this.form_1)
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.dialogVisiblexui = false
                this.getlist()
              } else {
                this.$message.error(res.message)
              }
              this.btn = true
            })
        }
        this.btn = false
      })
    },
    edit(id) {
      // this
      this.dialogVisiblexui = true
      this.form_1.id = id
      this.$http.get('/admin/News/getById?id=' + id).then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.form_1 = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/News/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/News/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addkuai() {
      this.diokuai = true
      this.getlista()
    },
    getlista() {
      this.$http
        .get('/admin/NewsTemp/getList', {
          params: {
            page: this.currentPagea,
            pageSize: this.pagesizea,
            source: this.source
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.lista = res.data.list
            this.totala = res.data.totalCount
            // console.log(this.currentPagea)
            // console.log(this.pagesizea)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addskuai() {
      if (this.btn) {
        this.$http
          .post('/admin/News/addAll', { ids: this.fastid })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.diokuai = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  },
  created() {
    this.getlist()
    sessionStorage.setItem('action', 'news')
  }
}
</script>

<style scoped>
.el-dialog__body .out {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;
}
.el-dialog__body .out .input {
  width: 37%;
  display: inline-block;
  margin-right: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
